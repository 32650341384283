import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import SearchBar from '../components/SearchBar'
import collections from '../data/Collections';
import ModalVideoInfo from '../components/ModalVideoInfo'
import SwimLaneEmptyState from '../components/SwimLaneEmptyState'
import SwimLaneFiltered from '../components/SwimLaneFiltered'
import SwimLaneOriginalSeries from '../components/SwimLaneOriginalSeries'
import SwimLane from '../components/SwimLane';

import sha1 from 'crypto-js/sha1';

function HRO() {

    const widgetId = "_1922831";
  const appTokenId = "1_sw1ywh3f";
  const appTokenValue = "77ed7f15a6ce2a399fb939cc6a4bb288";
  const sessionUserId = "lance.clack@adp.com";

  //const playlistIdFilter = "1_sxzohcku,1_bv779c80,1_1sa9fr0z,1_t58lg6vo,1_8kmv4ads,1_b1rth3f1,1_z1oh8ikk,1_x8xhe2lb,1_7v9rye8c,1_cn52itun,1_1twn39ze,1_den2ighe,1_8pslyeh9,1_etj6a8wi,1_o18uslgx,1_e3nh1fws,1_obrrq4wx,1_ttnnf5p6,1_fc0bozy2,1_2ucze55f,1_dokkeujm,1_sr973vbx,1_frojej82,1_92xlit3i,1_sopda4fd,1_rx8esl23,1_mqftifgq,1_4wlpkuxp,1_wziofwq1,1_19bjuagq,1_1ecn6mbt,1_0gmhccos,1_m4764auh,1_1d2ujv9u,1_5t9zu1ww,1_m14m0wrv,1_0urc0pe9";
  const playlistIdFilterA = "1_sxzohcku,1_bv779c80,1_1sa9fr0z,1_t58lg6vo,1_8kmv4ads,1_b1rth3f1,1_z1oh8ikk,1_x8xhe2lb,1_7v9rye8c,1_cn52itun,1_1twn39ze,1_den2ighe,1_8pslyeh9,1_etj6a8wi,1_o18uslgx,1_e3nh1fws,1_obrrq4wx,1_ttnnf5p6,1_fc0bozy2,1_2ucze55f,1_dokkeujm,1_sr973vbx,1_frojej82,1_92xlit3i,1_sopda4fd,1_rx8esl23,1_mqftifgq,1_4wlpkuxp,1_wziofwq1,1_19bjuagq,1_1ecn6mbt,1_0gmhccos,1_m4764auh,1_96onzy8c,1_1d2ujv9u,1_o7bzs7af,1_w1qtb15f,1_7lp10jzu,1_m75ds47v,1_6ipvl792,1_p2b3zuue,1_2d8t8b3s";
  const playlistIdFilterB = "1_78eg4g7b,1_i2ksdzjt,1_f4p17xa0,1_mr7yvgsl,1_7gyugv9b,1_r0rgpjp7,1_1kueex7i,1_ao13z6kl,1_8krfol12,1_5t9zu1ww,1_m14m0wrv,1_9gvxxk77,1_peqar7zu,1_artiiqrc,1_0urc0pe9,1_nbr391zn,1_yoj3yhob,1_ignr0wfy,1_1dqq3i3q";

  const [kalturaSession, setKalturaSession] = useState("");
  const [media, setMedia] = useState([]);
  const [playlists, setPlaylists] = useState([]);

  const [selectedCollection, setSelectedCollection] = useState(collections[0]);
  const [selectedEpisodeId, setSelectedEpisodeId] = useState("");

  const [allPlaylistIds, setAllPlaylistIds] = useState([]);

  const [allPlaylistContent, setAllPlaylistContent] = useState([]);

  const [q, setQ] = useState("");

  const [searchParam] = useState(["name", "description"]);

  const [showModal, setShowModal] = useState(false);

  const handleOnCloseModal = () => setShowModal(false)

  const toggleModal = (bool, collection, episodeId) => {
    setSelectedCollection(collection)
    setSelectedEpisodeId(episodeId)
    setShowModal(bool)
    //console.log("Button Clicked")
  }

  const startWidgetSession = async () => {
    axios.post('https://www.kaltura.com/api_v3/service/session/action/startWidgetSession?widgetId=_1922831&format=1')
    .then(res => {
      const json = res.data;
      //getTokenHash(json["ks"]);
      const stringToHash = json["ks"] + appTokenValue;
      const hashed = sha1(stringToHash);
      startAppTokenSession(json["ks"], hashed);
    })
  }

  const getTokenHash = async (ks) => {
    axios.get('https://api.hashify.net/hash/sha1/hex?value=' + ks + appTokenValue)
    .then(res => {
      const json = res.data;
      startAppTokenSession(ks, json["Digest"]);
      
    })
  }

  const startAppTokenSession = async (ks, tokenHash) => {
    axios.post('https://www.kaltura.com/api_v3/service/apptoken/action/startSession?ks=' + ks + '&id=' + appTokenId + '&tokenHash=' + tokenHash + '&userId=' + sessionUserId + '&format=1')
    .then(res => {
      const json = res.data;
      //console.log(json["ks"]);
      setKalturaSession(json["ks"]);
      //getMedia(json["ks"]);
      getPlaylists(json["ks"]);
    })
  }

  const getPlaylists = async (ks) => {
    console.log('getting playlists...');
    axios.post('https://www.kaltura.com/api_v3/service/playlist/action/list?ks=' + ks + '&format=1&filter[objectType]=KalturaMediaEntryFilter&filter[idIn]=' + playlistIdFilterA + '&filter[categoriesFullNameIn]=Sales Learning Plus&pager[objectType]=KalturaPager&pager[pageSize]=500')
    .then(res => {
      const json = res.data;
      //////setPlaylists(json["objects"]);
      //console.log(json["objects"].length + ' playlists');
      console.log(json["objects"]);
      let playlistContent = json["objects"].flatMap((pl) => pl["playlistContent"].split(','));
      //console.log('playlistContent size: ' + playlistContent.length)
      /////setAllPlaylistContent(playlistContent);
      getMedia(ks, json["objects"], playlistContent)
    })
  }

  const getMedia = async (ks, playlistArray, playlistContent) => {
    console.log('Getting media...');
    //console.log('getMedia')
    //axios.post('https://www.kaltura.com/api_v3/service/media/action/list?ks=' + ks + '&format=1&pager[objectType]=KalturaPager&pager[pageSize]=500&filter[objectType]=KalturaMediaEntryFilter&filter[categoriesFullNameIn]=Sales Learning Plus')
    axios.post('https://www.kaltura.com/api_v3/service/media/action/list?ks=' + ks + '&format=1&pager[objectType]=KalturaPager&pager[pageSize]=500&filter[objectType]=KalturaMediaEntryFilter&filter[idIn]=' + playlistContent)
    .then(res => {
      const json = res.data;
      //console.log('json response: ' + json["pager"]["pageSize"]);
      //setMedia(json["objects"]);
      //console.log('mediaaaa ' + media.length);
      //console.log(json["objects"].length + ' media');
      //console.log(json["objects"]);
      //getTopVideos(ks);
      //console.log("media[0]: " + media[0]);
      getMorePlaylists(ks, playlistArray, json["objects"], playlistContent);
    })
  }

  const getMorePlaylists = async (ks, playlistArray, mediaArray, p) => {
    console.log('getting more playlists...');
    axios.post('https://www.kaltura.com/api_v3/service/playlist/action/list?ks=' + ks + '&format=1&filter[objectType]=KalturaMediaEntryFilter&filter[idIn]=' + playlistIdFilterB + '&filter[categoriesFullNameIn]=Sales Learning Plus&pager[objectType]=KalturaPager&pager[pageSize]=500')
    .then(res => {
      const json = res.data;
      //////setPlaylists(json["objects"]);
      setPlaylists(playlistArray.concat(json["objects"]));
      //console.log('playlists: ' + playlists.length);
      //console.log(json["objects"].length + ' playlists');
      console.log(json["objects"]);
      let playlistContent = json["objects"].flatMap((pl) => pl["playlistContent"].split(','));
      //console.log('more playlistContent size: ' + playlistContent.length)
      /////setAllPlaylistContent(playlistContent);
      setAllPlaylistContent(p.concat(playlistContent));
      getMoreMedia(ks, mediaArray, playlistContent)
    })
  }

  const getMoreMedia = async (ks, mediaArray, morePlaylistContent) => {
    console.log('Getting more media...')
    axios.post('https://www.kaltura.com/api_v3/service/media/action/list?ks=' + ks + '&format=1&pager[objectType]=KalturaPager&pager[pageSize]=500&filter[objectType]=KalturaMediaEntryFilter&filter[idIn]=' + morePlaylistContent)
    .then(res => {
      const json = res.data;
      let allMedia = mediaArray.concat(json["objects"]);
      //setMedia(json["objects"]);
      setMedia(allMedia);
    })
  }

  function getPlaylistContent(playlistContent) {
    const idArray = playlistContent.split(',');

    var playlistMedia = media.filter(function (ep) {
        return idArray.includes(ep.id);
    });

    return playlistMedia;
  }

  function checkSearchQuery(episode) {
    return episode["name"].toLowerCase().includes(q.toLowerCase()) || episode["description"]?.toLowerCase().includes(q.toLowerCase()) || episode["tags"]?.toLowerCase().includes(q.toLowerCase());
  }

  function getAllPlaylistIds() {
        let newArr = collections.flatMap((c) => c["playlists"].flatMap((p) => p["playlistId"]));
        //console.log('testFlatMap: ' + newArr);
        setAllPlaylistIds(newArr);
    }

  function filteredMedia() {
      return media.filter(function (m) {
          return allPlaylistContent.includes(m.id);
      })
  }

  function getPlaylistForEpisode(episodeId) {
      var newArr = playlists.filter(function (pl) {
          return pl["playlistContent"].includes(episodeId);
      });

      //return newArr.length > 0 ? newArr[0]["name"] : "No Playlist";
      return newArr.length > 0 ? newArr[0] : { "name": "No Playlist" };
  }

    function getCollectionForPlaylist(playlistId) {
        var newArr = collections.filter(function (c) {
            return c["playlists"].flatMap((p) => p["playlistId"]).includes(playlistId);
        })

        return newArr.length > 0 ? newArr[0] : "No Collection";
    }

    function getPlaylistWithId(id) {
    var playlist = playlists.find(pl => {
        return pl.id === id
    });
    //console.log(playlist);
    return playlist;
  }

   function getMediaWithId(id) {
        var episode = media.find(ep => {
            return ep.id === id
        });

        //console.log(episode);
        return episode;
    }

      function getBUContent(bu) {
      
       var newArr = media.filter(function (m) {
        
          //return m["tags"]?.includes(keywordTag) && m["tags"]?.includes(bu);
          //return m["tags"]?.includes(bu);
          return m["tags"]?.includes(bu) || m["tags"]?.includes("allbus")
      });

      return newArr.reverse();
  }

    function getBUContentWithTag(bu, keywordTag) {

       var newArr = media.filter(function (m) {
          //return m["id"].includes("1_04iu01f9");
          //return m["tags"]?.includes(keywordTag);
          //return m["tags"]?.includes(keywordTag) && m["tags"]?.includes(bu);
          return m["tags"]?.includes(keywordTag) && (m["tags"]?.includes(bu) || m["tags"]?.includes("allbus"));
          //return m["tags"]?.includes(keywordTag);
      });

      return newArr;
  }

  useEffect(() => {
    startWidgetSession();
  }, []);

    return (
        <div className="App">
            <div className="bg-zinc-900">
                <div className="px-10 pt-10">
                  <label htmlFor="email" className="block text-4xl font-medium leading-6 text-gray-100 pb-4">
                      HRO
                  </label>
                </div>
                { playlists.length > 0 && media.length > 0 ?
                <div className="mx-auto max-w-full overflow-hidden px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                    
                    {/* { playlists.length > 0 && media.length > 0 ? <SwimLaneOriginalSeries kalturaSession={kalturaSession} name="Original Series" toggleModal={toggleModal} playlists={playlists} index="0" /> : <SwimLaneEmptyState name="Original Series" /> } */}
                    { playlists.length > 0 && media.length > 0 ? <SwimLane kalturaSession={kalturaSession} name="Best of BPO" playlist={getPlaylistWithId('1_1dqq3i3q')} media={getPlaylistContent(getPlaylistWithId('1_1dqq3i3q')["playlistContent"])} toggleModal={toggleModal} index="30" /> : <SwimLaneEmptyState name="Best of BPO" /> }
                    { playlists.length > 0 && media.length > 0 ? <SwimLane kalturaSession={kalturaSession} name="PhD in PEO" playlist={getPlaylistWithId('1_6ipvl792')} media={getPlaylistContent(getPlaylistWithId('1_6ipvl792')["playlistContent"])} toggleModal={toggleModal} index="24" /> : <SwimLaneEmptyState name="PhD in PEO" /> }
                    { playlists.length > 0 && media.length > 0 ? <SwimLane kalturaSession={kalturaSession} name="BS in Benefits" playlist={getPlaylistWithId('1_0urc0pe9')} media={getPlaylistContent(getPlaylistWithId('1_0urc0pe9')["playlistContent"])} toggleModal={toggleModal} index="25" /> : <SwimLaneEmptyState name="BS in Benefits" /> }
                    { playlists.length > 0 && media.length > 0 ? <SwimLane kalturaSession={kalturaSession} name="HRO Canada in 5" playlist={getPlaylistWithId('1_ignr0wfy')} media={getPlaylistContent(getPlaylistWithId('1_ignr0wfy')["playlistContent"])} toggleModal={toggleModal} index="26" /> : <SwimLaneEmptyState name="HRO Canada in 5" /> }
                    { playlists.length > 0 && media.length > 0 ? <SwimLaneFiltered kalturaSession={kalturaSession} name="HRO" playlists={playlists} media={getBUContent("hro")} toggleModal={toggleModal} index="0" /> : <SwimLaneEmptyState name="Selling Skills" /> }
                    
                    {/* { getBUContentWithTag("sbs", "product").length > 0 ? <SwimLaneFiltered kalturaSession={kalturaSession} name="Product" playlists={playlists} media={getBUContentWithTag("sbs", "product")} toggleModal={toggleModal} index="1" /> : <SwimLaneEmptyState name="Product" /> } */}
                    { getBUContentWithTag("hro", "product").length > 0 ? <SwimLaneFiltered kalturaSession={kalturaSession} name="HRO Product" playlists={playlists} media={getBUContentWithTag("hro", "product")} toggleModal={toggleModal} index="1" /> : <br /> }
                    { getBUContentWithTag("hro", "competitive intelligence").length > 0 ? <SwimLaneFiltered kalturaSession={kalturaSession} name="HRO Competitive Intelligence" playlists={playlists} media={getBUContentWithTag("hro", "competitive intelligence")} toggleModal={toggleModal} index="2" /> :  <br /> }
                    { getBUContentWithTag("hro", "tools").length > 0 ? <SwimLaneFiltered kalturaSession={kalturaSession} name="HRO Tools" playlists={playlists} media={getBUContentWithTag("hro", "tools")} toggleModal={toggleModal} index="3" /> :  <br /> }
                    { getBUContentWithTag("hro", "skills").length > 0 ? <SwimLaneFiltered kalturaSession={kalturaSession} name="HRO Skills" playlists={playlists} media={getBUContentWithTag("hro", "skills")} toggleModal={toggleModal} index="4" /> :  <br /> }
                    { getBUContentWithTag("hro", "messaging").length > 0 ? <SwimLaneFiltered kalturaSession={kalturaSession} name="HRO Messaging" playlists={playlists} media={getBUContentWithTag("hro", "messaging")} toggleModal={toggleModal} index="5" /> :  <br /> }
                    {/* { selectedTags.map((tag) => (
                      <SwimLaneFiltered kalturaSession={kalturaSession} name={tag} playlists={playlists} media={getBUContentWithTag(selectedBU.toLowerCase(), tag.toLowerCase())} toggleModal={toggleModal} index="1" />
                    ))} */}
                </div>
                :
                <div>nope</div>
                }
            </div>
            <ModalVideoInfo onClose={handleOnCloseModal} visible={showModal} kalturaSession={kalturaSession} collection={selectedCollection} media={media} playlists={playlists} episodeId={selectedEpisodeId} />
        </div>
    )
}

export default HRO;